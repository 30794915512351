import { useNavigate } from "react-router";
import { Background } from "../../UTILITIES/Background";
import { Clickable } from "../../UTILITIES/Clickable";
import { Footer } from "../../UTILITIES/Footer";
import { Navigation } from "../../UTILITIES/Navigation";
import { Spacer } from "../../UTILITIES/Spacer";
import { auth_CreateUser, auth_SignIn, firebase_CreateDocument } from "react-library/src/firebase";
import { useEffect, useState } from "react";
import { auth, db } from "../../firebase";

export function CourseSignup() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    async function onSignUp() {
        const email = document.querySelector('#tbEmail').value;
        const password = document.querySelector('#tbPassword').value;
        const passwordConf = document.querySelector('#tbConfirmPassword').value;

        if (email == "" || password == "" || passwordConf == "") {
            alert('Please fill out all fields.');
            return;
        }

        if (password !== passwordConf) {
            alert("Make sure your passwords match.");
            return;
        }

        setLoading(true);

        const args = {
            email: email,
        }

        await auth_CreateUser(auth, email, password, async (user) => {
            if (user !== null) {
                await firebase_CreateDocument(db, 'CourseUsers', user.uid, args, (success) => {
                    if (success) {
                        setLoading(false);
                        navigate('/courses')
                    } else {
                        setLoading(false);
                        alert("There was an error. Try again later.")
                    }
                })
            } else {
                setLoading(false);
                alert("There was an error. Try again later or use a different email.")
                return;
            }
        })

    }

    useEffect(() => {
        document.title = 'Course Signup - Innovative Internet Creations'
    }, []);

    return <div className="host white">
        <Background />
        <Navigation />

        <div className="main p separate-v">
            <div className="separate-h p-h">
                <div></div>
                <Clickable onPress={() => {
                    navigate('/course-login')
                }}>
                    <div className="p-sm fit-width full-radius">
                        <div className="" >
                            <p className="text-size10">log in</p>
                        </div>
                    </div>
                </Clickable>
            </div>
            {/* <div></div> */}
            <div className="horizontal-center box p" style={{ width: 320 }}>
                <h1 className="text-size18 line-sm">Welcome to the class..</h1>
                <br />
                <p>email</p>
                <Spacer height={5} />
                <input id="tbEmail" type="text" className="bg-dark5 no-border p radius full-width box white" placeholder="jdoe@gmail.com" />
                <Spacer height={20} />
                <p>password</p>
                <Spacer height={5} />
                <input id="tbPassword" type="password" className="bg-dark5 no-border p radius full-width box white" placeholder="********" />
                <Spacer height={20} />
                <p>confirm password</p>
                <Spacer height={5} />
                <input id="tbConfirmPassword" type="password" className="bg-dark5 no-border p radius full-width box white" placeholder="********" />
                <Spacer height={20} />
                <Clickable onPress={() => {
                    onSignUp();
                }}>
                    <div className="full-width text-center bg-blue-purple p-sm box radius">
                        <p>sign up</p>
                    </div>
                </Clickable>
            </div>
            <div></div>
            <div></div>
        </div>
        <Footer />
    </div>
}
import { FaArrowLeft } from "react-icons/fa";
import { Background } from "../../UTILITIES/Background";
import { Clickable } from "../../UTILITIES/Clickable";
import { Footer } from "../../UTILITIES/Footer";
import { Navigation } from "../../UTILITIES/Navigation";
import { useNavigate } from "react-router";
import { firebase_GetAllDocuments } from "react-library/src/firebase";
import { db } from "../../firebase";
import { useEffect, useState } from "react";
import { Loading } from "../../UTILITIES/Loading";
import { Spacer } from "../../UTILITIES/Spacer";
import { formatLongDate } from 'react-library/src/FUNCTIONS/date.js'
import { sortArrayByObj } from "react-library/src/FUNCTIONS/arrays";

export function CourseForum() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);

    async function onLoad() {
        setLoading(true)
        await firebase_GetAllDocuments(db, 'CoursePosts', (docs) => {
            setPosts(docs)
            setLoading(false)
        })
    }
    useEffect(() => {
        document.title = 'Course Forum - Innovative Internet Creations'
        onLoad();
    }, [])

    if (loading && posts.length == 0) {
        return <Loading />
    } else {
        return <div className="host white">
            <Background />
            <Navigation />
            <div className="main p">
                <div className="">
                    <Clickable onPress={() => {
                        navigate('/courses')
                    }}>
                        <div className="p-sm bg-dark4 fit-width full-radius border-1-white">
                            <div className="m-h flex-row gap-sm vertical-center" >
                                <FaArrowLeft size={14} />
                                <p className="">back</p>
                            </div>
                        </div>
                    </Clickable>

                </div>
                <Spacer />
                <div className="flex-column gap">
                    {
                        sortArrayByObj(posts, 'date', true).map((post, i) => {
                            return <div className="p box border-l-4-white bg-dark8 radius-tr radius-br box" key={i}>
                                <h2 className="thin line-sm">{post.title}</h2>
                                <br />
                                <p
                                    className="thin gray"
                                    dangerouslySetInnerHTML={{ __html: post.post.replaceAll("jjj", "<br />") }}
                                />
                                <br />
                                <div className="separate-h">
                                    <div></div>
                                    <div className="">
                                        <p className="orange text-size4">{formatLongDate(new Date(post.date))}</p>
                                    </div>
                                </div>

                            </div>
                        })
                    }
                </div>
            </div>
            <Footer />
        </div>
    }
}
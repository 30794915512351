import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";

import adsMahem from '../IMAGES/ads-mayhem-logo.png'
import petGrooming from '../IMAGES/pet-grooming-logo.jpg'
import genkiManga from '../IMAGES/genki-manga-logo.png'
import wanderPass from '../IMAGES/wander-pass-logo.png'
import practiceNow from '../IMAGES/practice-now-logo.png'
import nationalCity from '../IMAGES/national-city-logo.jpg'
import { Spacer } from "../UTILITIES/Spacer";
import { Clickable } from "../UTILITIES/Clickable";
import { useNavigate } from "react-router";
import { Background } from "../UTILITIES/Background";
import { BsChevronRight } from "react-icons/bs";

export function Projects() {
    const navigate = useNavigate();

    return <div className="host white">
        <Background />
        <Navigation />
        <div className="main p">
            <Spacer />
            <div className="row">
                <div className="p-v horizontal-center md2 box">
                    <h1 className="thin text-size26 line-sm">Projects that are shaping the future for everyone.</h1>
                </div>
            </div>
            <Spacer />
            {/* ADS MAYHEM */}
            <div className="row gap box ">
                <div className="md2">
                    <h2 className="text-size20 normal">Ads Mayhem</h2>
                    <p className="thin orange">User & Business Apps ver. 2.0</p>
                    <br />
                    <div className="bg-dark8 p">
                        <p className="text-size8 thin">Ads belong where people want to see them. Our platform showcases ads and coupons to users who actively seek them, boosting engagement and increasing purchase likelihood.
                            <br />
                            <br />
                            For more information, check out the app details and links.</p>
                        <br />
                        <div className="separate-h">
                            <div></div>
                            <Clickable onPress={() => {
                                navigate('/apps#ads-mayhem')
                            }}>
                                <BsChevronRight size={26} className="orange" />
                            </Clickable>
                        </div>
                    </div>
                </div>
                <div className="md2">
                    <img src={adsMahem} className="full-width contain radius" style={{ maxHeight: 400 }} />
                </div>
            </div>
            <Spacer />
            {/* PET GROOMING */}
            <div className="row gap box ">
                <div className="md2">
                    <h2 className="text-size20 normal">Pet Grooming Edu</h2>
                    <p className="thin orange">Student Web Portal ver. 2.0</p>
                    <br />
                    <div className="bg-dark8 p">
                        <p className="text-size8 thin">
                            The San Diego Grooming Academy offers students a streamlined online portal where they can access lessons, complete homework, and take tests—all in one place. It’s the most efficient and comprehensive way to master the essentials of pet grooming.
                            <br />
                            <br />
                            Course content is developed by Myke Ross, a renowned pet grooming expert based in San Diego, California.
                        </p>
                        <br />
                        <div className="separate-h">
                            <div></div>
                            <Clickable onPress={() => {
                                window.open('https://petgroomingedu.web.app', "_blank")
                            }}>
                                <BsChevronRight size={26} className="orange" />
                            </Clickable>
                        </div>
                    </div>
                </div>
                <div className="md2">
                    <img src={petGrooming} className="full-width contain radius" style={{ maxHeight: 400 }} />
                </div>
            </div>
            <Spacer />
            {/* GENKI MANGA */}
            <div className="row gap box ">
                <div className="md2">
                    <h2 className="text-size20 normal">Genki Pocket Manga</h2>
                    <p className="thin orange">Mobile App ver. 1.0</p>
                    <br />
                    <div className="bg-dark8 p">
                        <p className="text-size8 thin">
                            The Genki Pocket Manga app is a platform designed for manga creators worldwide to share and sell their work to a global audience—without the constraints of traditional publishing. The app transforms manga into a mobile-friendly format, making it effortless for readers to enjoy their favorite stories on the go. Unlike traditional book formats that don’t fit well on a phone screen, Genki Pocket Manga ensures an optimized and seamless reading experience for today’s mobile lifestyle.
                            <br />
                            <br />
                            If you are interested in selling your manga, contact us right away!
                        </p>
                    </div>
                </div>
                <div className="md2">
                    <img src={genkiManga} className="full-width contain radius" style={{ maxHeight: 400 }} />
                </div>
            </div>
            <Spacer />
            {/* WANDER PASSPORT */}
            <div className="row gap box ">
                <div className="md2">
                    <h2 className="text-size20 normal">Wander Passport</h2>
                    <p className="thin orange">Mobile App ver. 1.0</p>
                    <br />
                    <div className="bg-dark8 p">
                        <p className="text-size8 thin">
                            Traveling just got more exciting with Wander Passport! Connect with fellow travelers or locals, discover events and activities in your destination, manage your itinerary, and much more—all in one amazing app.
                            <br />
                            <br />
                            Launch date is coming soon.
                        </p>
                    </div>
                </div>
                <div className="md2">
                    <img src={wanderPass} className="full-width contain radius" style={{ maxHeight: 400 }} />
                </div>
            </div>
            <Spacer />
            {/* PRACTICE NOW*/}
            <div className="row gap box ">
                <div className="md2">
                    <h2 className="text-size20 normal">Practice Now</h2>
                    <p className="thin orange">Mobile App ver. 1.0</p>
                    <br />
                    <div className="bg-dark8 p">
                        <p className="text-size8 thin">
                            The bond between a student and teacher is vital for musical growth. Mastering an instrument takes dedication and consistent practice. Practice Now! bridges the gap by connecting students and teachers through a mobile platform, streamlining assignments, practice sessions, tools, and tracking progress to ensure success.
                        </p>
                        <br />
                        <div className="separate-h">
                            <div></div>
                            <Clickable onPress={() => {
                                navigate('/apps#practice-now')
                            }}>
                                <BsChevronRight size={26} className="orange" />
                            </Clickable>
                        </div>
                    </div>
                </div>
                <div className="md2">
                    <img src={practiceNow} className="full-width contain radius" style={{ maxHeight: 400 }} />
                </div>
            </div>
            <Spacer />
            {/* NATIONAL CITY */}
            <div className="row gap box ">
                <div className="md2">
                    <h2 className="text-size20 normal">City of National City</h2>
                    <p className="thin orange">Mobile App ver. 1.0</p>
                    <br />
                    <div className="bg-dark8 p">
                        <p className="text-size8 thin">
                            The future of National City is deeply intertwined with our mission and values. As a business rooted in this community, we take pride in the fact that our founder was born and raised here. Their vision has always been to help transform National City into a thriving, modern hub of innovation. That’s why we’ve dedicated ourselves to developing a series of apps designed to serve the community and address long-standing challenges. By introducing forward-thinking solutions, we aim to drive meaningful progress and revitalize systems that have remained stagnant for decades.
                        </p>
                    </div>
                </div>
                <div className="md2 p box">
                    <img src={nationalCity} className="full-width contain radius" style={{ maxHeight: 400 }} />
                </div>
            </div>
        </div>

        <Footer />
    </div>
}
import { Background } from "../UTILITIES/Background";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import { Spacer } from "../UTILITIES/Spacer";

export function About() {
    return <div className="host white">
        <Background />
        <Navigation />
        <div className="main">
            <div className="row gap box">
                <div className="p md2 lg1 xl2">
                    <h1 className="thin text-size24">Our mission</h1>
                </div>
                <div className="p md2 lg2 xl2 box">
                    <p className="extra-thin text-size12">Our mission is to deliver innovative websites, apps, and software solutions that empower businesses, organizations, and communities, using cutting-edge technology to create meaningful connections and drive progress for people of all backgrounds.</p>
                </div>
            </div>
            <Spacer />
            <div className="row gap">
                <div className="p md2 lg1 xl2">
                    <h1 className="thin text-size24 orange">Who are we?</h1>
                </div>
                <div className="p-lg md2 lg2 xl2 bg-dark8 box">
                    <p>
                        Innovative Internet Creations was founded by <b>Jesus Jimenez Santos</b> in San Diego, California, with a vision to shape the future through groundbreaking technology. What began as innovative software solutions quickly evolved into solutions designed for people. Everyone deserves technology that solves real-life problems with simplicity and ease—eliminating confusion and frustration.
                        <br /><br />
                        Our commitment to creating cutting-edge, user-friendly products sets us apart from companies that prioritize profits over people. At Innovative Internet Creations, we are driven by the mission to deliver solutions that truly make a difference in daily lives.</p>
                </div>
            </div>
            <Spacer />
            <div className="row gap">
                <div className="p md2 lg1 xl2">
                    <h1 className="thin text-size24 orange">Our expansion</h1>
                </div>
                <div className="p-lg md2 lg2 xl2 bg-dark8 box">
                    <p>
                        We are rapidly expanding to other countries, partnering with professionals and businesses ready to take the next step towards automation in their logistics. Whether it’s shipping companies or small local restaurants, we are committed to reaching out globally and finding innovative ways to help businesses streamline their operations and thrive in an increasingly automated world.</p>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}
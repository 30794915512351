import { BsChevronUp } from "react-icons/bs";
import { Spacer } from "./Spacer";
import { Clickable } from "./Clickable";

export function Footer() {
    return <div className="">
        <Spacer />
        <div className="host white p box separate-h vertical-center">

            <p className="orange thin text-size4">the nothing bagel lives.</p>
            <p className="text-size4 gray">Innovative Internet Creations LLC 2025.</p>
            <Clickable
                onPress={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                }}
            >
                <BsChevronUp size={26} className="orange" />
            </Clickable>
        </div>
    </div>
}
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import { Spacer } from "../UTILITIES/Spacer";

import blocksToBytes from '../IMAGES/blocks-to-logo.gif'
import { Clickable } from "../UTILITIES/Clickable";
import { Background } from "../UTILITIES/Background";
import { BsChevronRight } from "react-icons/bs";

export function Programs() {
    return <div className="white host">
        <Background />
        <Navigation />
        <Spacer />
        <div className="main p">
            <div className="row">
                <div className="p-v horizontal-center md2 box">
                    <h1 className="thin text-size26 line-sm">Programs that serve the community using education and solutions.</h1>
                    <br />
                    <p className="thin text-size8">
                        We are proud members of an innovative society, driven by the vision of empowering individuals and advancing their potential. Our mission is to develop programs that leverage our expertise to educate, inspire, and solve real-world challenges. Each program is uniquely designed with a specific purpose, yet they all share a common goal: to help people grow smarter, stronger, and more capable in every area of life.
                    </p>
                </div>
            </div>
            <Spacer />
            {/* BLOCKS TO BYTES */}
            <div className="row gap">
                <div className="md2 box">
                    <h2 className="text-size20 normal box">Blocks to Bytes</h2>
                    <br />
                    <div className="bg-dark8 p">
                        <p className="text-size8 thin">
                            We’ve designed several programs tailored to different age groups, all built around the same structure and shared goals. Our primary aim is to inspire students to unleash their creativity in a 3D simulator, enabling them to do anything they imagine. We teach them the core programming concepts and how to use the web program, starting with activities like placing items in 3D space, moving objects, tracking collisions, and much more. As students progress, they dive into activities that encourage them to create to their heart's content, exploring more complex tasks along the way.
                            <br /><br />

                            Unlike other educational companies, we offer students the chance to create real, functional programs, collaborating with established institutions and organizations. Through these partnerships, students develop programs that are actively used by real people, earning recognition for their contributions. This hands-on approach not only highlights their skills but also demonstrates the meaningful impact they can have on the world.
                            <br /><br />
                            This method fosters excitement and encourages students to share their unique experiences with peers who may have chosen different activities. Our dedicated instructors are always ready to support students as they explore and learn through any activity they choose.
                        </p>
                        <div className="separate-h">
                            <div></div>
                            <Clickable onPress={() => {
                                window.open('https://blockstobytes.com', '_blank')
                            }}>
                                <BsChevronRight size={26} className="orange" />
                            </Clickable>
                        </div>
                    </div>
                </div>
                <div className="md2 box">
                    <img src={blocksToBytes} className="full-width contain radius" style={{ maxHeight: 400 }} />
                </div>
            </div>
        </div>
        <Footer />
    </div>
}
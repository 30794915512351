import { useNavigate } from "react-router";
import { Clickable } from "../UTILITIES/Clickable";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import { Spacer } from "../UTILITIES/Spacer";
import { Background } from "../UTILITIES/Background";

export function Apps() {
    const navigate = useNavigate();

    return <div className="host white">
        <Background />
        <Navigation />
        <Spacer />
        <div className="main p">
            <div className="row p-v">
                <div className="md2">
                    <h1 className="thin text-size26 line-sm">Our mobile applications</h1>
                </div>
            </div>
            <Spacer />
            <div className="row gap">
                <div className="p box md2">
                    <h2 className="text-size14 thin orange">Ads Mayhem</h2>
                    <br />
                    <p className="text-size7">Ads should appear where they are welcome—not forced into spaces where they’re unwelcome. Ads Mayhem provides a dedicated platform for businesses to showcase their ads in a place designed for people who actively want to see them. This ensures that potential customers are genuinely interested, increasing the likelihood of turning views into real conversions. Unlike other platforms, Ads Mayhem eliminates bots, scammers, and spam accounts, offering a space filled with real people and authentic engagement.</p>
                </div>
                <div className="p box text-right md2">
                    <Clickable onPress={() => {
                        window.open('https://play.google.com/store/apps/details?id=com.iicdev.AdsMahem&hl=en_US&pli=1', '_blank')
                    }}>
                        <p className="orange underline">Ads Mayhem (Google Play)</p>
                    </Clickable>
                    <Clickable onPress={() => {
                        window.open('https://apps.apple.com/us/app/ads-mayhem/id6737462117', '_blank')
                    }}>
                        <p className="underline">Ads Mayhem (Apple)</p>
                    </Clickable>
                    <br />
                    <Clickable onPress={() => {
                        window.open('https://play.google.com/store/apps/details?id=com.iicdev.AdsMahemBusiness', '_blank')
                    }}>
                        <p className="orange underline">Ads Mayhem Business (Google Play)</p>
                    </Clickable>
                    <Clickable onPress={() => {
                        window.open('https://apps.apple.com/us/app/ads-mayhem-business/id6737673889', '_blank')
                    }}>
                        <p className="underline">Ads Mayhem Business (Apple)</p>
                    </Clickable>
                    <br />
                    <br />
                    <Clickable onPress={() => {
                        navigate('/app-policies#ads-mayhem')
                    }}>
                        <p className="gray">App Policy</p>
                    </Clickable>
                </div>
            </div>
            <Spacer />
            <div className="row gap">
                <div className="p box md2">
                    <h2 className="text-size14 thin orange">Practice Now!</h2>
                    <br />
                    <p className="text-size7">
                        A strong connection between a student and teacher is essential for developing musical skills. Consistent and focused practice is key to mastering an instrument, but without proper guidance, progress can slow or stall. Practice Now! bridges this gap by providing a mobile platform that enhances the student-teacher relationship. It offers tools for managing assignments, organizing practice schedules, accessing resources, and tracking achievements. By streamlining communication and fostering better organization, the app helps students stay motivated and on course to reach their musical potential.
                    </p>
                </div>
                <div className="p box text-right md2">
                    <Clickable onPress={() => {
                        window.open('https://apps.apple.com/us/app/practice-now/id1612123384', '_blank')
                    }}>
                        <p className="underline">Practice Now! (Apple)</p>
                    </Clickable>
                    <br />
                    <br />
                    <Clickable onPress={() => {
                        navigate('/app-policies#practice-now')
                    }}>
                        <p className="gray">App Policy</p>
                    </Clickable>
                </div>
            </div>
            <Spacer />
            <div className="row gap">
                <div className="p box md2">
                    <h2 className="text-size14 thin purple">Edmusica App Series</h2>
                    <br />
                    <p className="text-size7">
                        Edmusica is a music education and publishing company dedicated to making learning music easy and enjoyable. Over the years, it has provided music education to school districts worldwide. To support its expansion, we collaborated to develop three innovative apps designed for administrators, teachers, students, and parents. These tools streamline operations, enhance efficiency, and ensure that music programs run smoothly in any district. By integrating these apps, we empower schools with the resources needed to foster growth and success in their music education programs.
                    </p>
                </div>
                <div className="p box text-right md2">
                    <p>Contact us to get a link if you are an Escuela de Musica partner.</p>
                    <br />
                    <br />
                    <Clickable onPress={() => {
                        navigate('/app-policies#edmusica');
                    }}>
                        <p className="gray">App Policy</p>
                    </Clickable>
                </div>
            </div>
        </div>
        <Footer />
    </div >
}
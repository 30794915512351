import { useNavigate } from "react-router";
import { Background } from "../../UTILITIES/Background";
import { Clickable } from "../../UTILITIES/Clickable";
import { Footer } from "../../UTILITIES/Footer";
import { Navigation } from "../../UTILITIES/Navigation";
import { Spacer } from "../../UTILITIES/Spacer";
import { auth_SignIn } from "react-library/src/firebase";
import { useEffect } from "react";
import { auth } from "../../firebase";

export function CourseLogin() {

    const navigate = useNavigate()

    async function onLogin() {
        const email = document.querySelector('#tbEmail').value;
        const password = document.querySelector('#tbPassword').value;

        if (email !== "" && password !== "") {
            await auth_SignIn(auth, email, password, (user) => {
                if (user !== null) {
                    navigate('/courses')
                } else {
                    console.log("Invalid credentials. Please try again.")
                }
            })
        }
    }

    useEffect(() => {
        document.title = 'Course Login - Innovative Internet Creations'
    }, []);

    return <div className="host white">
        <Background />
        <Navigation />

        <div className="main p separate-v">
            <div className="separate-h p-h">
                <div></div>
                <Clickable onPress={() => {
                    navigate('/course-signup')
                }}>
                    <div className="p-sm fit-width full-radius">
                        <div className="" >
                            <p className="orange text-size10">sign up</p>
                        </div>
                    </div>
                </Clickable>
            </div>
            {/* <div></div> */}
            <div className="horizontal-center box p" style={{ width: 320 }}>
                <h1 className="text-size22">Login</h1>
                <br />
                <p>email</p>
                <Spacer height={5} />
                <input id="tbEmail" type="text" className="bg-dark5 no-border p radius full-width box white" placeholder="jdoe@gmail.com" />
                <Spacer height={20} />
                <p>password</p>
                <Spacer height={5} />
                <input id="tbPassword" type="password" className="bg-dark5 no-border p radius full-width box white" placeholder="********" />
                <Spacer height={20} />
                <Clickable onPress={() => {
                    onLogin();
                }}>
                    <div className="full-width text-center bg-orange p-sm box radius">
                        <p>log in</p>
                    </div>
                </Clickable>
            </div>
            <div></div>
            <div></div>
        </div>
        <Footer />
    </div>
}
import { useNavigate, useParams } from "react-router";
import { Background } from "../../UTILITIES/Background";
import { Footer } from "../../UTILITIES/Footer";
import { Navigation } from "../../UTILITIES/Navigation";
import { FaArrowLeft } from "react-icons/fa";
import { Clickable } from "../../UTILITIES/Clickable";
import { auth_CheckUser, firebase_CreateDocument, firebase_GetAllDocumentsQueried, firebase_GetDocument, storage_DownloadMedia } from "react-library/src/firebase";
import { useEffect, useState } from "react";
import { auth, db, storage } from "../../firebase";
import { Loading } from "../../UTILITIES/Loading";
import { randomString } from 'react-library/src/FUNCTIONS/misc.js'

export function CourseLesson() {
    const navigate = useNavigate();
    const { lessonId, courseId } = useParams();
    const [lesson, setLesson] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [videoWatched, setVideoWatched] = useState(false);
    const [me, setMe] = useState(null)

    async function onLoad() {
        var lesson = null;
        await firebase_GetDocument(db, 'Lessons', lessonId, async (doc) => {
            setLesson(doc);
            lesson = doc;
            document.title = `${doc.name} - Innovative Internet Creations`;
            await storage_DownloadMedia(storage, doc.videoPath, (url) => {
                setVideoUrl(url);
            });
        });
        await auth_CheckUser(auth, async (user) => {
            if (user !== null) {
                await firebase_GetDocument(db, 'CourseUsers', user.uid, async (doc) => {
                    if (doc !== null) {
                        setMe(doc)

                        await firebase_GetAllDocumentsQueried(db, 'CourseViews', [
                            { field: 'userId', operator: '==', value: user.uid },
                            { field: 'lessonId', operator: '==', value: lessonId }
                        ], (docs) => {
                            if (docs.length > 0) {
                                setVideoWatched(true)
                            }
                        })
                    }
                })
            } else {
                if (lesson.isFree == undefined || !lesson.isFree) {
                    navigate('/course-login')
                }
            }
        })

    }

    async function onWatchedVideo() {
        setVideoWatched(true)
        if (me != null) {
            await firebase_CreateDocument(db, 'CourseViews', randomString(25), {
                userId: me.id,
                lessonId: lesson.id,
                courseId: lesson.courseId
            }, (success) => {
                if (success) {
                    console.log("WATCHED")
                }
            });
        }
    }

    useEffect(() => {
        onLoad();
    }, [])

    if (lesson == null || videoUrl == null) {
        return <Loading />
    } else {
        return <div className="host white">
            <Background />
            <Navigation />

            <div className="main">
                <div className="p separate-h vertical-center">
                    <Clickable onPress={() => {
                        navigate(`/courses/${courseId}`)
                    }}>
                        <div className="p-sm bg-dark4 fit-width full-radius border-1-white">
                            <div className="m-h flex-row gap-sm vertical-center" >
                                <FaArrowLeft size={14} />
                                <p className="">back</p>
                            </div>
                        </div>
                    </Clickable>

                    {
                        videoWatched && <div className="p-sm full-radius">
                            <p className="thin m-h orange">video completed!</p>
                        </div>
                    }
                </div>
                <div className="bg-black">
                    <video
                        controls
                        src={videoUrl}
                        className="box"
                        style={{ width: "100%", height: "75vh" }}
                        onContextMenu={(e) => e.preventDefault()}
                        onEnded={onWatchedVideo} // Trigger when video ends
                    />
                </div>
                <br />
                <div className="row gap p box">
                    {
                        lesson.note != "" && <div className="box p bg-dark3 radius md2 lg1 xl1">
                            <h3>note</h3>
                            <br />
                            <p dangerouslySetInnerHTML={{ __html: lesson.note.replaceAll('jjj', '<br/>') }} />
                        </div>
                    }
                    <div className="box p bg-dark4 radius md2 lg2 xl3">
                        <h3>details</h3>
                        <br />
                        <p dangerouslySetInnerHTML={{ __html: lesson.desc.replaceAll('jjj', '<br/>') }} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    }
}
import { useNavigate, useParams } from "react-router";
import { Background } from "../../UTILITIES/Background";
import { Clickable } from "../../UTILITIES/Clickable";
import { Footer } from "../../UTILITIES/Footer";
import { Navigation } from "../../UTILITIES/Navigation";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect, useState } from "react";
import { auth_CheckUser, auth_SignOut, firebase_GetAllDocumentsQueried, firebase_GetDocument } from "react-library/src/firebase";
import { auth, db } from "../../firebase";
import { Loading } from "../../UTILITIES/Loading";
import { Spacer } from "../../UTILITIES/Spacer";
import { GrLogout } from "react-icons/gr";
import { FaCircleCheck } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";
import { sortArrayByObj } from 'react-library/src/FUNCTIONS/arrays.js'
import { server_POST } from 'react-library/src/SERVER/server.js'

export function Course() {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const [loading, setLoading] = useState(false);
    const [course, setCourse] = useState(null);
    const [lessons, setLessons] = useState([]);
    const [views, setViews] = useState([])
    const [signedIn, setSignedIn] = useState(false);
    const [me, setMe] = useState(null)
    const [isPaid, setIsPaid] = useState(false);


    async function onLoad() {
        var courseDoc;
        var lessonDocs;
        var courseViews;
        var meDoc = null;

        setLoading(true)

        await firebase_GetDocument(db, 'Courses', courseId, (doc) => {
            courseDoc = doc;
        });
        await firebase_GetAllDocumentsQueried(db, 'Lessons', [
            { field: 'courseId', operator: '==', value: courseId }
        ], (docs) => {
            lessonDocs = docs;
        })

        await auth_CheckUser(auth, async (user) => {
            if (user != undefined) {
                setSignedIn(true);
                await firebase_GetDocument(db, 'CourseUsers', user.uid, async (doc) => {
                    setMe(doc)

                    await firebase_GetAllDocumentsQueried(db, 'CourseViews', [
                        { field: 'userId', operator: '==', value: doc.id },
                        { field: 'courseId', operator: '==', value: courseId },
                    ], (docs) => {
                        setViews(docs)
                    })

                    await firebase_GetAllDocumentsQueried(db, 'CoursePayments', [
                        { field: 'userId', operator: '==', value: doc.id },
                        { field: 'courseId', operator: '==', value: courseId },
                    ], (docs) => {
                        if (docs.length > 0) {
                            setIsPaid(true)
                        }
                    })
                })
            }
        })



        setCourse(courseDoc);
        if (courseDoc != null) {
            document.title = `${courseDoc.name} - Innovative Internet Creations`
        }
        setLessons(lessonDocs);
        setLoading(false);

    }

    useEffect(() => {
        onLoad()

        window.scrollTo(0, 0)
    }, []);

    if (loading || lessons.length == 0 || course == null) {
        return <Loading />
    } else {
        return <div className="host white">
            <Background />
            <Navigation />
            <div className="main p">
                <div className="">
                    <Clickable onPress={() => {
                        navigate('/courses')
                    }}>
                        <div className="p-sm bg-dark4 fit-width full-radius border-1-white">
                            <div className="m-h flex-row gap-sm vertical-center" >
                                <FaArrowLeft size={14} />
                                <p className="">back</p>
                            </div>
                        </div>
                    </Clickable>

                </div>
                <div className="separate-h">
                    <div></div>

                    {/* NOT SIGNED IN */}
                    {
                        me == null && <div className="flex-row gap-sm">
                            <Clickable onPress={() => {
                                navigate('/course-login')
                            }}>
                                <div className="p-sm fit-width full-radius">
                                    <div className="" >
                                        <p className="text-size10">log in</p>
                                    </div>
                                </div>
                            </Clickable>
                            <Clickable onPress={() => {
                                navigate('/course-signup')
                            }}>
                                <div className="p-sm fit-width full-radius">
                                    <div className="" >
                                        <p className="orange text-size10">sign up</p>
                                    </div>
                                </div>
                            </Clickable>
                        </div>
                    }
                    {/* SIGNED IN */}
                    {
                        signedIn && me != null
                        &&
                        <div className="flex-row gap vertical-center">
                            <p>{me.email}</p>
                            <Clickable onPress={async () => {
                                await auth_SignOut(auth, (success) => {
                                    navigate('/course-login')
                                })
                            }}>
                                <GrLogout color="red" size={20} />
                            </Clickable>
                        </div>
                    }
                </div>
                <Spacer />
                <div className="row">
                    <div className="md2 box">
                        <h1 className="text-size20 normal line-sm">{course.name}</h1>
                        <br />
                        <p className="text-size8 gray">{course.desc}</p>
                        <br />
                        <div className="separate-h">
                            <p className="orange">{course.total}</p>
                            <p className="text-size10">${course.price.toFixed(2)}</p>
                        </div>
                    </div>
                </div>
                <Spacer />
                <div className="flex-column gap">
                    {
                        sortArrayByObj(lessons, 'order').map((lesson, i) => {
                            return <div key={i} className="bg-dark8 border-1-gray radius vertical-center box">
                                <Clickable classes={'p'} onPress={async () => {
                                    if (lesson.isFree) {
                                        navigate(`/courses/${courseId}/${lesson.id}`)
                                        return;
                                    }

                                    if (!signedIn) {
                                        navigate('/course-login')
                                    } else {
                                        // CHECK IF PAID
                                        if (isPaid) {
                                            navigate(`/courses/${courseId}/${lesson.id}`)
                                        } else {
                                            // PAY HERE
                                            setLoading(true)
                                            const url = await server_POST('iic-create-payment-link', {
                                                userId: me.id,
                                                courseName: course.name,
                                                email: me.email,
                                                courseId: course.id,
                                                amount: course.price * 100,
                                                currency: 'USD',
                                                customerId: me.customerId !== undefined ? me.customerId : "",
                                                app: 'innovative-internet-creations'
                                            });
                                            window.location.href = url.url;
                                        }
                                    }

                                }}>
                                    <div className="full-width separate-h">
                                        <p className="normal text-size8"><span className="text-size4 gray">{lesson.order}.</span> {lesson.name}</p>

                                        <div className="flex-row gap vertical-center">
                                            {
                                                lesson.isFree && <div className="full-radius border-1-gray bg-dark3" style={{ padding: 3 }}>
                                                    <p className="text-size3 m-h">free</p>
                                                </div>
                                            }
                                            <p>{lesson.duration}</p>
                                            {
                                                views.map((ting) => ting.lessonId).includes(lesson.id) && <FaCircleCheck size={22} className="orange" />
                                            }
                                            <FaChevronRight size={20} />
                                        </div>
                                    </div>
                                </Clickable>
                            </div>
                        })
                    }
                </div>

            </div>
            <Footer />
        </div>
    }
}
import { useEffect, useState } from "react";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import { auth_CheckUser, auth_SignOut, firebase_GetAllDocuments, firebase_GetDocument } from 'react-library/src/firebase.js'
import { auth, db } from '../firebase.js'
import { Spacer } from "../UTILITIES/Spacer.js";
import { Background } from "../UTILITIES/Background.js";
import { Loading } from '../UTILITIES/Loading.js'
import { Clickable } from "../UTILITIES/Clickable.js";
import { useNavigate } from "react-router";
import { GrLogout } from "react-icons/gr";

export function Courses() {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [me, setMe] = useState(null);
    const [signedIn, setSignedIn] = useState(false);

    async function onLoad() {
        // 
        setLoading(true);
        await firebase_GetAllDocuments(db, 'Courses', (docs) => {
            setCourses(docs)
        })

        await auth_CheckUser(auth, async (user) => {
            if (user != undefined) {
                setSignedIn(true);
                await firebase_GetDocument(db, 'CourseUsers', user.uid, async (doc) => {
                    setMe(doc)

                })
            }
        })

        setLoading(false)
    }

    useEffect(() => {
        onLoad();
    }, []);

    if (loading) {
        return <Loading />
    } else {
        return <div className="host white">
            <Background />
            <Navigation />
            <div className="main">
                <div className="separate-h">
                    <div></div>

                    {/* NOT SIGNED IN */}
                    {
                        me == null && <div className="flex-row gap-sm">
                            <Clickable onPress={() => {
                                navigate('/course-login')
                            }}>
                                <div className="p-sm fit-width full-radius">
                                    <div className="" >
                                        <p className="text-size10">log in</p>
                                    </div>
                                </div>
                            </Clickable>
                            <Clickable onPress={() => {
                                navigate('/course-signup')
                            }}>
                                <div className="p-sm fit-width full-radius">
                                    <div className="" >
                                        <p className="orange text-size10">sign up</p>
                                    </div>
                                </div>
                            </Clickable>
                        </div>
                    }
                    {/* SIGNED IN */}
                    {
                        signedIn && me != null
                        &&
                        <div className="flex-row gap vertical-center">
                            <p>{me.email}</p>
                            <Clickable onPress={async () => {
                                await auth_SignOut(auth, (success) => {
                                    navigate('/course-login')
                                })
                            }}>
                                <GrLogout color="red" size={20} />
                            </Clickable>
                        </div>
                    }
                </div>
                <div className="row gap p-v box">
                    <div className="md2 p">
                        <h1 className="thin text-size22">Courses</h1>
                    </div>
                    <div className="md2 lg1 xl2">
                        <Clickable onPress={() => {
                            navigate('/course-forum')
                        }}>
                            <h2 className="text-size14 underline text-right p">Course Forum</h2>
                        </Clickable>
                    </div>
                </div>
                <div className="row">
                    <div className="md2">
                        <p className=" text-size8 p">Our mission is to teach the world the most effective and straightforward ways to achieve goals. We specialize in creating practical solutions for both life and business. Forget unnecessary fillers or outdated lessons—everything we offer is current and relevant. Plus, we provide powerful tools to simplify learning and execution, making your journey smoother and more efficient.</p>
                    </div>
                </div>
                <Spacer />
                <div className="flex-column gap-lg p">
                    {
                        courses.map((course, i) => {
                            return <div key={i} className="row gap-lg p-lg box">
                                <div className="md2 lg2 xl3">
                                    <h3 className="text-size16">{course.name}</h3>
                                    <br />
                                    <p className="text-size8 thin gray">{course.desc}</p>
                                    <br />
                                    <div className="separate-h">
                                        <div></div>
                                        <Clickable onPress={() => {
                                            navigate(`/courses/${course.id}`)
                                        }}>
                                            <div className="bg-orange full-radius p-sm border-1-white">
                                                <p className="text-size5 m-h">Accio Course!</p>
                                            </div>
                                        </Clickable>
                                    </div>
                                </div>
                                <div className="md2 lg1 xl1">
                                    <img src={course.path} className="full-width contain" style={{ maxHeight: 300 }} />
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            <Footer />
        </div>
    }


}
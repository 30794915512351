import { useEffect } from "react";
import { Background } from "../UTILITIES/Background";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import { Spacer } from "../UTILITIES/Spacer";

export function Contact() {

    useEffect(() => {
        document.title = 'Contact Us - Innovative Internet Creations'
    }, [])
    return <div className="host white">
        <Background />
        <Navigation />
        <div className="main p">
            <div className="horizontal-center" style={{ maxWidth: 600 }}>
                <h1 className="text-size20 normal">Contact Us</h1>
                <br />
                <p className="text-size8">For any questions or information, feel free to contact us via email or phone. To help us assist you more efficiently, please include “Inquiry” in the subject line along with your message.</p>
                <Spacer />
                <div className="p-lg ">
                    <p className="gray">main</p>
                    <h2><span className="orange">bagel.inbox</span>@gmail.com</h2>
                    <p className="gray">secondary</p>
                    <h2><span className="orange">bagel2.inbox</span>@gmail.com</h2>
                    <p className="gray">company</p>
                    <h2><span className="orange">info</span>@innovativeinternetcreations.com</h2>
                    <br />
                    <p className="gray">phone (text-preferred)</p>
                    <h2>+1 760.208.0335</h2>
                </div>
                <Spacer />
                <p className="orange text-size8">
                    Based in San Diego, CA, US.<br />
                    Tokyo, Japan.<br />
                    Bangkok, Thailand.<br />
                    Guadalajara, Mexico.
                </p>
            </div>
        </div>
        <Footer />
    </div>
}
export function Background() {
    return (
        <div
            className="fixed bg-black"
            style={{
                zIndex: -10,
                // backgroundColor: "#0d0d0d",
                backgroundImage: `
                    linear-gradient(rgba(255, 255, 255, 0.07) 1px, transparent 1px),
                    linear-gradient(90deg, rgba(255, 255, 255, 0.07) 1px, transparent 1px)
                `,
                backgroundSize: "80px 80px", // Adjust grid spacing
                width: "100vw",
                height: "100vh",
                inset: 0
            }}
        />
    );
}

export function Background2() {
    const generateStars = (count) => {
        let stars = "";
        for (let i = 0; i < count; i++) {
            const x = Math.random() * 100;
            const y = Math.random() * 100;
            const size = Math.random() * 2 + 1;
            const opacity = Math.random() * 0.5 + 0.5; // Varying opacity for different shades
            stars += `radial-gradient(${size}px ${size}px at ${x}% ${y}%, rgba(255, 255, 255, ${opacity}), transparent),`;
        }
        return stars.slice(0, -1); // Remove the last comma
    };

    return (
        <div
            className="fixed"
            style={{
                zIndex: -10,
                width: "100vw",
                height: "100vh",
                inset: 0,
                background: "black",
                backgroundImage: generateStars(400)
            }}
        />
    );
}



import { Background } from "../UTILITIES/Background";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import { Spacer } from "../UTILITIES/Spacer";
import img1 from '../IMAGES/instructors.png'
import { Clickable } from "../UTILITIES/Clickable";
import { useEffect } from "react";

export function Jobs() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <div className="host white">
        <Background />
        <Navigation />
        <div className="main p">

            <div className="row">
                <div className="md2">
                    <h1 className="text-size22 normal">Jobs</h1>
                    <p className="text-size8">Our jobs are designed to offer more than just a paycheck. We aim to equip you with valuable skills that empower you to grow, succeed independently, and fully benefit from the opportunities that come with the role.</p>
                </div>
            </div>
            <Spacer />
            <div className="border-1-gray p row box gap-lg">
                <div className="md2 lg2 xl2">
                    <h1 className="text-size12 normal p-sm p-h bg-dark border-l-4-white">Blocks To Bytes Instructor</h1>
                    <Spacer height={10} />
                    <p className="gray">April - July 2025</p>
                    <p className="orange">20 positions open</p>
                    <Spacer />
                    <h3 className="normal gray">Starting Hourly Rate:</h3>
                    <p>$20 USD per hour /
                        <br />$22 USD per hour for bilingual instructors (eng/spa)</p>
                    <br />
                    <h3 className="normal gray">Location</h3>
                    <p>National City, CA Elementary Schools</p>
                    <br />
                    <h3 className="normal gray">Schedule</h3>
                    <p>Monday – Friday, 3:30 PM – 5:30 PM</p>
                    <p>Saturday: TBD - 2 hour training</p>
                    <Spacer />
                    <h2>About Blocks To Bytes</h2>
                    <p>Blocks to Bytes is an innovative after-school program designed to teach students the fundamentals of coding through engaging 2D and 3D programming using JavaScript. Our goal is to make coding fun, accessible, and meaningful by integrating computer science with critical thinking, problem-solving, and creativity.</p>
                    <Spacer height={20} />
                    <h2>About the role</h2>
                    <p className="text-size7">As a Blocks to Bytes Instructor, you will play a vital role in mentoring students throughout their coding journey. Your responsibilities will include delivering engaging, interactive lessons, fostering a supportive learning environment, and helping students develop crucial programming skills. No prior coding experience is necessary, as we offer thorough training to ensure you feel fully prepared to lead the program.
                        <br /><br />
                        This after-school position requires you to report to a designated school at the start of after-school hours, teach two one-hour sessions, and submit a report at the end of each session outlining the day’s activities, student progress, and any questions or concerns. You will begin teaching in April 2025 and continue through the summer. <b className="orange">Fall start date will vary from your assigned school district</b>.</p>
                    <Spacer height={20} />
                    <h2>What you will do</h2>
                    <ul>
                        <li>Teach and guide students through 2D and 3D programming activities using JavaScript.</li>
                        <li>Create an engaging and supportive learning environment that encourages creativity and problem-solving.</li>
                        <li>Work closely with a co-instructor to follow structured lessons.</li>
                        <li>Support students in completing coding challenges, debugging code, and understanding key programming concepts.</li>
                        <li>Monitor student progress and submit a report about class activity every day.</li>
                        <li>Ensure a safe and positive classroom atmosphere where students feel encouraged to experiment and learn.</li>
                    </ul>
                    <Spacer height={20} />
                    <h2>Requirements</h2>
                    <ul>
                        <li>Must be at least 18 years old.</li>
                        <li>High school diploma or equivalent required.</li>
                        <li>Availability to work Monday–Friday from 3:30 PM to 5:30 PM and attend training sessions.</li>
                        <li>Reliable transportation to any assigned school in National City, ensuring punctuality.</li>
                        <li>Ability to stand for extended periods and move around the school campus and classroom as needed.</li>
                        <li>Strong ability to work independently and collaboratively in a team environment.</li>
                        <li>Willingness to complete training in 2D and 3D JavaScript programming.</li>
                        <li>Prior experience working with students is preferred but not required.</li>
                    </ul>
                    <Spacer height={20} />
                    <h2>Perks</h2>
                    <ul>
                        <li><strong>Free Training</strong> – Learn everything you need to know about 2D and 3D JavaScript programming.</li>
                        <li><strong>Paid School Breaks & Holidays</strong> – Enjoy paid time off during school breaks and holidays.</li>
                        <li><strong>Inspire Young Minds</strong> – Make a real impact by introducing students to the world of coding.</li>
                        <li><strong>Team Environment</strong> – Work alongside another instructor in a fun and engaging setting.</li>
                        <li><strong>Hands-On Learning</strong> – Gain valuable teaching experience while enhancing your own technical skills.</li>
                        <li><strong>Recommendation Letter</strong> – Provide a letter of recommendation to support future opportunities in education and career advancement.</li>
                    </ul>
                    <br />
                    <Clickable>
                        <p className="text-size8">Learn more about Blocks To Bytes by visiting our website: <span className="orange underline">https://blockstobytes.com</span></p>
                    </Clickable>

                    <Spacer />
                    <p className="text-size10">If you’re passionate about education, technology, and inspiring the next generation of innovators, we’d love to hear from you!</p>
                </div>

                <div className="md2 lg1 xl2">
                    <img src={img1} className="full-width cover" />
                </div>
            </div>
        </div>
        <Footer />

    </div>
}
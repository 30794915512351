// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCIFGVe8rYbtty5qnkYFIg-0OUJhnU0LpI",
    authDomain: "iic-development.firebaseapp.com",
    projectId: "iic-development",
    storageBucket: "iic-development.appspot.com",
    messagingSenderId: "264501829712",
    appId: "1:264501829712:web:dba679ac95f127ecfb7155",
    measurementId: "G-7X4LT5GKJB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app)
import { useLocation } from "react-router";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import { Spacer } from "../UTILITIES/Spacer";
import { useEffect } from "react";
import { Background } from "../UTILITIES/Background";

export function AppPolicies() {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1)); // Remove "#" from hash
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    const apps = [
        {
            name: 'Ads Mayhem',
            date: '12/13/2024',
            anchor: 'ads-mayhem'
        },
        {
            name: 'EDM Admin',
            date: '1/1/2025',
            anchor: 'edm-admin'
        },
        {
            name: 'EDM Teachers App',
            date: '1/1/2025',
            anchor: 'edm-teachers'
        },
        {
            name: 'EDM Students',
            date: '1/1/2025',
            anchor: 'edm-students'
        },
        {
            name: 'Practice Now!',
            date: '1/1/2023',
            anchor: 'practice-now'
        }
    ]



    return <div className="host white">
        <Background />
        <Navigation />
        <div className="main">
            <div className="box p">
                <h1 className="thin text-size22">App Policies</h1>
            </div>
            <Spacer />
            {
                apps.map((app, i) => {
                    return <div className="box p" key={i} id={app.anchor}>
                        <h1 className="orange thin">{app.name}</h1>
                        <p className="thin text-size5 gray">Effective Date: {app.date}</p>
                        <p className="thin">This Privacy Policy outlines how Innovative Internet Creations ("we," "our," or "us") collects, uses, shares, and protects your personal information when you access or use our mobile application ({app.name}). By using the App, you agree to the terms of this Privacy Policy.</p>
                        <br />
                        <div className="row gap">
                            <div className="md2">
                                <h3 className="text-size10">1. Information We Collect</h3>
                            </div>
                            <div className="md2">
                                <p>When you create an account on our App, we collect your email address and display name. Additionally, when you create a new sale and list items for sale, we may collect information such as your address to facilitate pickup by buyers.</p>
                            </div>
                        </div>
                        <br />
                        <div className="row gap">
                            <div className="md2">
                                <h3 className="text-size10">2. How We Use Your Information</h3>
                            </div>
                            <div className="md2">
                                <p>
                                    2.1 Providing Services: We use the information you provide to operate, maintain, and improve our App, as well as to communicate with you about your account and sales activities.
                                    <br />
                                    2.2 Personalization: We may use your email address to send you promotional emails, newsletters, or updates about new features or offerings that may be of interest to you. You can opt-out of receiving these communications at any time by following the unsubscribe instructions provided in the emails.
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="row gap">
                            <div className="md2">
                                <h3 className="text-size10">3. Sharing Your Information</h3>
                            </div>
                            <div className="md2">
                                <p>
                                    We may disclose your information if required to do so by law or in response to a valid legal request, such as a subpoena or court order.
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="row gap">
                            <div className="md2">
                                <h3 className="text-size10">4. Data Security</h3>
                            </div>
                            <div className="md2">
                                <p>
                                    We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="row gap">
                            <div className="md2">
                                <h3 className="text-size10">5. Children's Privacy</h3>
                            </div>
                            <div className="md2">
                                <p>
                                    Our App is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us at info@happycodewebsites.com so that we can take appropriate action.
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="row gap">
                            <div className="md2">
                                <h3 className="text-size10">6. Changes to this Privacy Policy</h3>
                            </div>
                            <div className="md2">
                                <p>
                                    We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes to this Privacy Policy by posting the updated policy on our App. Your continued use of the App after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className="row gap">
                            <div className="md2">
                                <h3 className="text-size10">7. Contact Us</h3>
                            </div>
                            <div className="md2">
                                <p>
                                    If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <b>bagel.inbox@gmail.com</b>.
                                </p>
                            </div>
                        </div>
                        <Spacer />
                    </div>
                })
            }
        </div>
        <Footer />
    </div>
}